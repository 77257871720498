import { Button } from "../Button";
import { getErrorMessage } from "@timeedit/registration-shared";
import { Title } from "../Typography/Title";
import { Text } from "../Typography/Text";
import "./errorInfo.scss";
import { SeverityIcon } from "@timeedit/ui-components";
import { ErrorLogId } from "../ErrorLogId";

interface Translations {
  retry: string;
  title: string;
}

interface ErrorInfoProps {
  translations: Translations;
  error: unknown;
  recover: () => void;
}

export function ErrorInfo({ translations, error, recover }: ErrorInfoProps) {
  const message = getErrorMessage(error);
  const newLineText = message.split("\n").map((str, index) => (
    <Text type="secondary" key={index}>
      {str}
    </Text>
  ));

  return (
    <div className="error-info__container">
      <div className="error-info-icon__container">
        <SeverityIcon alt="Error" severity="error" size={72} />
      </div>
      <Title level={3}>{translations.title}</Title>
      {newLineText}
      <br />
      <ErrorLogId error={error} />
      <Button type="primary" onClick={recover}>
        {translations.retry}
      </Button>
    </div>
  );
}
