import { en } from "./en";
import {
  EAppProperties,
  EApplicationObjectTypeGroup,
} from "@timeedit/types/lib/enums";

const systemRoles: Record<EApplicationObjectTypeGroup, string> = {
  [EApplicationObjectTypeGroup.NONE]: "Ingen",
  [EApplicationObjectTypeGroup.ROOM]: "Rum",
  [EApplicationObjectTypeGroup.PROFESSOR]: "Professor",
  [EApplicationObjectTypeGroup.COURSE]: "Kurs",
  [EApplicationObjectTypeGroup.COURSE_INFORMATION]: "Kursinformation",
  [EApplicationObjectTypeGroup.INVOICING]: "Fakturering",
  [EApplicationObjectTypeGroup.STUDENT_SET]: "Studentset",
  [EApplicationObjectTypeGroup.STUDENT_SET_GROUP]: "Studentsetgrupp",
  [EApplicationObjectTypeGroup.PROGRAM]: "Program",
  [EApplicationObjectTypeGroup.ACTIVITY_TYPE]: "Activitetsgrupp",
  [EApplicationObjectTypeGroup.STUDENT]: "Student",
  [EApplicationObjectTypeGroup.INVIGILATOR]: "Tentamensvakt",
  [EApplicationObjectTypeGroup.STAFF]: "Personal",
  [EApplicationObjectTypeGroup.CHANGE_REQUEST]: "Förändringsförfrågning",
};

const columnNames: Record<EAppProperties, string> = {
  [EAppProperties.NONE]: "Inget",
  [EAppProperties.NAME]: "Namn",
  [EAppProperties.LABEL]: "Etikett",
  [EAppProperties.EMAIL]: "Epost",
  [EAppProperties.FIRST_NAME]: "Förnamn",
  [EAppProperties.LAST_NAME]: "Efternamn",
  [EAppProperties.ROOM_TYPE]: "Rumstyp",
  [EAppProperties.REASONABLE_ADJUSTMENT]: "Rimlig justering",
  [EAppProperties.TITLE]: "Titel",
  [EAppProperties.ID]: "Id",
  [EAppProperties.STATE]: "Status",
  [EAppProperties.SEMESTER]: "Termin",
  [EAppProperties.MAX_STUDENTS]: "Max antal studenter",
  [EAppProperties.WAITLIST]: "Väntelista",
  [EAppProperties.ACTIVITY_TYPE]: "Aktivitetstyp",
  [EAppProperties.START_DATE]: "Startdatum",
  [EAppProperties.END_DATE]: "Slutdatum",
  [EAppProperties.ALLOW_REGISTRATION]: "Tillåt registrering",
  [EAppProperties.AUTO_ALLOCATION_ACTIVATED]: "Autoallokering aktiverad",
  [EAppProperties.BUFFER]: "Buffer",
  [EAppProperties.LINKTRACK]: "Länkat",
  [EAppProperties.HIDETRACK]: "Gömt",
  [EAppProperties.DEDICATETRACK]: "Tilldelat",
  [EAppProperties.TRACK]: "Spår",
  [EAppProperties.ROOM_NAME]: "Rumsnamn",
  [EAppProperties.SEATS]: "Kapacitet",
  [EAppProperties.DISTRIBUTION_RULE]: "Distributionregel",
  [EAppProperties.COURSE_OCCURENCE]: "Kurstillfälle",
  [EAppProperties.PLANNED_SIZE]: "Planerad storlek",
  [EAppProperties.REGISTRATION_PERIOD_SET]: "Har registreringsperiod",
  [EAppProperties.PROGRAM]: "Program",
};

export const sv: typeof en = {
  action: "Handling",
  actionNeeded: "Handling erfordras",
  addRow: "Lägg till rad",
  admin: "Admin",
  allocation: "Allokering",
  allocated: "Allokerad",
  allocationObjects: "Allokeringsobjekt",
  chooseFilters: "Välj filter",
  chooseSettings: "Välj inställningar",
  clash: "Schemakonflikt",
  clashMessage: "Det finns en konflikt mellan följande registrationer",
  clearFilter: "Rensa filter",
  closed: "Stängd",
  closedBecause: "Stängd för att",
  create: "Skapa",
  day: "Dag",
  delete: "Radera",
  deleteSelected: "Rader markerade",
  defaultServerErrorMessage:
    "Det verkar vara ett problem med att ansluta till servern. Vänligen försök igen.",
  done: "Klar",
  duration: "Varaktighet",
  editSettings: "Ändra inställningar",
  register: "Registrera",
  registration: "Registrering",
  registrationInfo: "Registreringsinfo",
  filter: "Filtrera",
  forcedClose: "Tvingad stängd",
  home: "Hem",
  lastRegistrationDate: "Sista datum för registrering",
  loading: "Laddar...",
  logout: "Logga ut",
  multipleAllocations: "Flertal registreringar",
  multipleMessage: "Följande aktiviteter har flera registrerade spår",
  navigate: "Navigera till",
  pendingChangeRequests: "Väntande förändringsförfrågningar",
  newChanges: "new changes",
  noAllocationGroups: "Inga allokeringsgrupper",
  noEnrolledCourses: "Inga registrerade kurser",
  noNotifications: "Inga notiser",
  notAllocated: "Inte allokerad",
  notRegistered: "Inte Registrerad",
  noticeText: "Notiser",
  ok: "Ok",
  registered: "Registrerad",
  registrationOpenUntil: "Registrering öppen till",
  registrationClosed: "Registrering stängd",
  registrationOpens: "Registrering öppnar",
  registrationDescription: "Gör dina val nedan",
  registrationIs: "Registreringsperioden är",
  registrationOpen: "Registrering öppen för",
  relation: "Relation",
  rename: "Döp om",
  restoreNotifications: "Återställ stängda notiser",
  retry: "Försök igen",
  pleaseTryAgain: "Var god och försök igen",
  errorInRegistration: "Fel vid registrering",
  schedule: "Schema",
  seats: "Platser",
  select: "Välj",
  setDefault: "Sätt som förval",
  setTypeWarning:
    "Du har fält mappade för den här typen. Ta bort dem innan du byter typ.",
  settings: "Inställningar",
  submit: "Skicka",
  swap: "Byt",
  time: "Tid",
  week: "Vecka",
  weekAbbreviation: "v",
  welcomeTo: "Välkommen till",
  welcome: "Välkommen",
  registerToClasses: "Registrera till de kurser som passar dina preferences",
  youHave: "Du har",
  conflict: "Konflikt",
  noSeatsLeft: "Inga platser kvar",
  deallocateConfirm: "Är du säker på att du vill avregistrera?",
  deallocate: "Avregistrera",
  optionsAvailable: "Tillgängliga alternativ",
  optionsAvailableOne: "1 tillgängligt alternativ",
  optionsAvailableNone: "Inga alternativ",
  classType: "Kurstillfälle",
  sortBy: "Sortera efter",
  close: "Stäng",
  textview: "Lista",
  graphicalview: "Kalender",
  teServerDown:
    "Det verkar vara ett problem att ansluta till servern. Försök igen om en stund",
  conflictErrorText: "Vänligen välj ett annat alternativ",
  conflictErrorTitle: "Konflikt",
  dedicatedErrorText: "Detta alternativ är tilldelat för andra student grupper",
  dedicatedErrorTitle: "Tilldelat alternativ",
  sizeErrorTitle: "Alternativet är fullt",
  sizeErrorText: "Vänligen välj ett annat alternativ",
  highDemandErrorTitle: "Hög efterfrågan",
  doubleBookedErrorText:
    "Vänligen avregistrera från denna aktivitet innan du väljer ett annat alternativ",
  doubleBookedErrorTitle: "Redan registrerad på denna aktivitet",
  hiddenErrorTitle: "Dolt alternativ",
  hiddenErrorText: "Vänligen välj ett annat alternativ",
  ...systemRoles,
  ...columnNames,
};
